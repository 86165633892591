<template>
  <div class="app-container">
    <div class="outer">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item
          label-width="150px"
          label="申请人姓名："
          prop="applyName"
        >
          <el-input
            v-model="ruleForm.applyName"
            class="w_350"
            size="mini"
            maxlength="30"
            show-word-limit
            placeholder="请输入申请人姓名"
            :readonly="disabled"
          />
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="联系电话："
          prop="mobile"
        >
          <el-input
            v-model="ruleForm.mobile"
            class="w_350"
            size="mini"
            maxlength="30"
            type="number"
            show-word-limit
            placeholder="请输入联系电话"
            :readonly="disabled"
          />
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="农博店铺名称："
          prop="storeName"
        >
          <el-input
            v-model="ruleForm.storeName"
            class="w_350"
            size="mini"
            maxlength="30"
            show-word-limit
            placeholder="请输入农博店铺名称"
            :readonly="disabled"
          />
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="企业名称："
          prop="supplierName"
        >
          <el-input
            v-model="ruleForm.supplierName"
            class="w_350"
            size="mini"
            maxlength="30"
            show-word-limit
            placeholder="请输入企业名称"
            :readonly="disabled"
          />
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="商家归属地："
          prop="storeProvince"
        >
          <SelectSite
            :disabled="disabled"
            :site-type="query.type"
            :site-value="[ruleForm.storeProvince, ruleForm.storeCity, ruleForm.storeArea]"
            @change="changeStoreSite"
          />
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="生产加工所在地区："
          prop="productProvince"
        >
          <SelectSite
            :disabled="disabled"
            :site-type="query.type"
            :site-value="[ruleForm.productProvince, ruleForm.productCity, ruleForm.productArea]"
            @change="changeProductSite"
          />
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="生产加工详细地址："
          prop="productAddress"
        >
          <el-input
            v-model="ruleForm.productAddress"
            class="w_350"
            size="mini"
            maxlength="30"
            show-word-limit
            placeholder="请输入生产加工详细地址"
            :readonly="disabled"
          />
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="营业执照："
        >
          <UpImage
            :disabled="disabled"
            :image-list="businessLicense"
            :num="1"
          />
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="当地农办资质审核证明"
        >
          <UpImage
            :disabled="disabled"
            :image-list="agriculturalLicense"
            :num="1"
          />
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="工商登记证明"
        >
          <UpImage
            :disabled="disabled"
            :image-list="businessCertificate"
            :num="1"
          />
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="经营许可证"
        >
          <UpImage
            :disabled="disabled"
            :image-list="operateLicense"
            :num="1"
          />
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="商品批次食品安全检测报告"
        >
          <UpImage
            :disabled="disabled"
            :image-list="foodReport"
            :num="1"
          />
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="生产许可证（非自家生产主体提供）"
        >
          <UpImage
            :disabled="disabled"
            :image-list="productLicense"
            :num="1"
          />
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="生产加工实景视频（1分钟以上）："
        >
          <UploadVideoOss
            ref="videoRef"
            :disabled="disabled"
            :url="ruleForm.productVideo"
            @success="changeVideo"
          ></UploadVideoOss>
        </el-form-item>
        <el-form-item
          required
          label-width="150px"
          label="商品图片"
        >
          <UpImage
            :disabled="disabled"
            :image-list="goodsImages"
            :num="8"
          />
        </el-form-item>
      </el-form>

      <div>
        <el-button
          size="mini"
          @click="backPage"
        >
          返回
        </el-button>
        <el-button
          v-if="query.type === 'edit'"
          type="primary"
          size="mini"
          @click="submitForm"
        >
          保存
        </el-button>
        <el-button
          v-if="query.type === 'auth'"
          type="primary"
          size="mini"
          @click="submitAuth('pass')"
        >
          通过审核
        </el-button>
        <el-button
          v-if="query.type === 'auth'"
          type="danger"
          size="mini"
          @click="submitAuth('refuse')"
        >
          拒绝审核
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import UpImage from '@/components/common/upImage/index';
import SelectSite from '@/components/common/selectSite/index';
import UploadVideoOss from '@/components/common/uploadVideoOss/index.vue';
export default {
  name: '',
  components: {
    UpImage,
    SelectSite,
    UploadVideoOss
  },
  props: {},
  data() {
    return {
      query: {},
      disabled: false,
      ruleForm: {
        applyName: '',
        mobile: '',
        storeName: '',
        supplierName: '',

        storeProvince: '',
        storeCity: '',
        storeArea: '',

        productProvince: '',
        productCity: '',
        productArea: '',

        productVideo: '', // 视频
      },
      businessLicense: [], // 营业执照
      agriculturalLicense: [], // 当地农办资质审核证明
      businessCertificate: [], // 工商登记证明
      operateLicense: [], // 经营许可证
      foodReport: [], // 商品批次食品安全检测报告
      productLicense: [], // 生产许可证（非自家生产主体提供）
      goodsImages: [], // 商品图片
      formImageName: [
        {name: '营业执照', id: 'businessLicense'},
        {name: '当地农办资质审核证明', id: 'agriculturalLicense'},
        {name: '工商登记证明', id: 'businessCertificate'},
        {name: '经营许可证', id: 'operateLicense'},
        {name: '商品批次食品安全检测报告', id: 'foodReport'},
        {name: '生产许可证（非自家生产主体提供）', id: 'productLicense'},
        {name: '商品图片', id: 'goodsImages'},
      ],
      rules: {
        applyName: [
          { required: true, message: '请输入申请人姓名', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        storeName: [
          { required: true, message: '请输入农博店铺名称', trigger: 'blur' },
        ],
        supplierName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        storeProvince: [
          { required: true, message: '请选择商家归属地', trigger: 'change' },
        ],
        productProvince: [
          { required: true, message: '生产加工所在地区', trigger: 'change' },
        ],
        productAddress: [
          { required: true, message: '请输生产加工详细地址', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.query = this.$route.query;
    if (this.query.id) {
      this.getDetail();
      if (this.query.type === 'show' || this.query.type === 'auth') {
        this.disabled = true;
      }
    }
  },
  methods: {
    getDetail() {
      this.$axios
        .get(this.$api.brand_certification.detail_by_id, {
          params: {
            id: this.query.id
          }
        })
        .then((res) => {
          if (res.code === 0) {
            this.ruleForm = res.data;
            // 回填图片数据
            this.formImageName.forEach(item => {
              if (this.ruleForm[item.id]) {
                this[item.id] = this.ruleForm[item.id].split(',')
              }
            })
          }
        })
    },
    // 提交表单
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 校验图片
          for (let item of this.formImageName) {
            if (!this[item.id] || this[item.id].length === 0) {
              this.$message.error('请上传' + item.name)
              return
            }
          }
          // 校验视频
          if (!this.ruleForm.productVideo) {
            this.$message.error('请上传生产加工实景视频')
            return
          }
          if (this.$refs.videoRef.videoTime < 60) {
            this.$message.error('视频时长需超过1分钟')
            return
          }
          this.$confirm('是否确认保存？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            console.log(this.ruleForm)
            let params = {
              ...this.ruleForm
            }
            this.formImageName.forEach(item => {
              params[item.id] = this[item.id].join(',');
            })
            this.$axios
              .post(this.$api.brand_certification.edit, params)
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success('操作成功！')
                  this.$router.go(-1);
                }
              })
          }).catch(() => {
          });
        };
      });
    },
    changeStoreSite(e) {
      [this.ruleForm.storeProvince, this.ruleForm.storeCity, this.ruleForm.storeArea] = e;
    },
    changeProductSite(e) {
      [this.ruleForm.productProvince, this.ruleForm.productCity, this.ruleForm.productArea] = e;
    },
    backPage() {
      this.$confirm('是否确认返回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.go(-1);
      }).catch(() => {

      });
    },
    // 视频
    changeVideo(e) {
      this.ruleForm.productAddress = e
    },
    // 审核
    submitAuth(type) {
      let message = type === 'pass' ? '是否确认审核通过？' : '是否确认据拒绝审核？';
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.brand_certification.operate_status, {
            id: this.ruleForm.id,
            status: type === 'pass' ? 1 : 2
          })
          .then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功！')
              this.$router.go(-1);
            }
          })
      }).catch(() => {

      });
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 20px;
  font-weight: bold;
}
.w_360 {
    width: 360px;
}
.w_300 {
    width: 300px;
}
.w_350 {
    width: 350px;
}
.w_450 {
    width: 450px;
}
.outer ::v-deep .m_b_40 {
    margin-bottom: 40px;
}
</style>
