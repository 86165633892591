var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "110px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  "label-width": "150px",
                  label: "申请人姓名：",
                  prop: "applyName",
                },
              },
              [
                _c("el-input", {
                  staticClass: "w_350",
                  attrs: {
                    size: "mini",
                    maxlength: "30",
                    "show-word-limit": "",
                    placeholder: "请输入申请人姓名",
                    readonly: _vm.disabled,
                  },
                  model: {
                    value: _vm.ruleForm.applyName,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "applyName", $$v)
                    },
                    expression: "ruleForm.applyName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  "label-width": "150px",
                  label: "联系电话：",
                  prop: "mobile",
                },
              },
              [
                _c("el-input", {
                  staticClass: "w_350",
                  attrs: {
                    size: "mini",
                    maxlength: "30",
                    type: "number",
                    "show-word-limit": "",
                    placeholder: "请输入联系电话",
                    readonly: _vm.disabled,
                  },
                  model: {
                    value: _vm.ruleForm.mobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "mobile", $$v)
                    },
                    expression: "ruleForm.mobile",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  "label-width": "150px",
                  label: "农博店铺名称：",
                  prop: "storeName",
                },
              },
              [
                _c("el-input", {
                  staticClass: "w_350",
                  attrs: {
                    size: "mini",
                    maxlength: "30",
                    "show-word-limit": "",
                    placeholder: "请输入农博店铺名称",
                    readonly: _vm.disabled,
                  },
                  model: {
                    value: _vm.ruleForm.storeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "storeName", $$v)
                    },
                    expression: "ruleForm.storeName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  "label-width": "150px",
                  label: "企业名称：",
                  prop: "supplierName",
                },
              },
              [
                _c("el-input", {
                  staticClass: "w_350",
                  attrs: {
                    size: "mini",
                    maxlength: "30",
                    "show-word-limit": "",
                    placeholder: "请输入企业名称",
                    readonly: _vm.disabled,
                  },
                  model: {
                    value: _vm.ruleForm.supplierName,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "supplierName", $$v)
                    },
                    expression: "ruleForm.supplierName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  "label-width": "150px",
                  label: "商家归属地：",
                  prop: "storeProvince",
                },
              },
              [
                _c("SelectSite", {
                  attrs: {
                    disabled: _vm.disabled,
                    "site-type": _vm.query.type,
                    "site-value": [
                      _vm.ruleForm.storeProvince,
                      _vm.ruleForm.storeCity,
                      _vm.ruleForm.storeArea,
                    ],
                  },
                  on: { change: _vm.changeStoreSite },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  "label-width": "150px",
                  label: "生产加工所在地区：",
                  prop: "productProvince",
                },
              },
              [
                _c("SelectSite", {
                  attrs: {
                    disabled: _vm.disabled,
                    "site-type": _vm.query.type,
                    "site-value": [
                      _vm.ruleForm.productProvince,
                      _vm.ruleForm.productCity,
                      _vm.ruleForm.productArea,
                    ],
                  },
                  on: { change: _vm.changeProductSite },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  "label-width": "150px",
                  label: "生产加工详细地址：",
                  prop: "productAddress",
                },
              },
              [
                _c("el-input", {
                  staticClass: "w_350",
                  attrs: {
                    size: "mini",
                    maxlength: "30",
                    "show-word-limit": "",
                    placeholder: "请输入生产加工详细地址",
                    readonly: _vm.disabled,
                  },
                  model: {
                    value: _vm.ruleForm.productAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "productAddress", $$v)
                    },
                    expression: "ruleForm.productAddress",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "营业执照：",
                },
              },
              [
                _c("UpImage", {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _vm.businessLicense,
                    num: 1,
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "当地农办资质审核证明",
                },
              },
              [
                _c("UpImage", {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _vm.agriculturalLicense,
                    num: 1,
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "工商登记证明",
                },
              },
              [
                _c("UpImage", {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _vm.businessCertificate,
                    num: 1,
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "经营许可证",
                },
              },
              [
                _c("UpImage", {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _vm.operateLicense,
                    num: 1,
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "商品批次食品安全检测报告",
                },
              },
              [
                _c("UpImage", {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _vm.foodReport,
                    num: 1,
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "生产许可证（非自家生产主体提供）",
                },
              },
              [
                _c("UpImage", {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _vm.productLicense,
                    num: 1,
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "生产加工实景视频（1分钟以上）：",
                },
              },
              [
                _c("UploadVideoOss", {
                  ref: "videoRef",
                  attrs: {
                    disabled: _vm.disabled,
                    url: _vm.ruleForm.productVideo,
                  },
                  on: { success: _vm.changeVideo },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  required: "",
                  "label-width": "150px",
                  label: "商品图片",
                },
              },
              [
                _c("UpImage", {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _vm.goodsImages,
                    num: 8,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.backPage } },
              [_vm._v(" 返回 ")]
            ),
            _vm.query.type === "edit"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v(" 保存 ")]
                )
              : _vm._e(),
            _vm.query.type === "auth"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.submitAuth("pass")
                      },
                    },
                  },
                  [_vm._v(" 通过审核 ")]
                )
              : _vm._e(),
            _vm.query.type === "auth"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.submitAuth("refuse")
                      },
                    },
                  },
                  [_vm._v(" 拒绝审核 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }